import * as React from "react"
import { StoreContext } from "../context/store-context"
import { Button } from "react-bootstrap"
import classnames from "classnames"
import { PlusCircle } from "react-bootstrap-icons"
import { localVariantsToItems } from "../utils/gtag"

export default function AddToCartButton({ product, variant, variantId, quantity, available, className, ...props }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)

    // Event tracking
    const addToCardEventData = {
      currency: product.priceRangeV2.minVariantPrice.currencyCode,
      amount: quantity * Number(variant.price),
      items: localVariantsToItems([{
        product: product,
        variant: variant,
        quantity: quantity,
      }]),
    }
    typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.gtag("event", "add_to_cart", addToCardEventData)
  }

  return (
    <Button
      type="submit"
      variant={available ? "primary" : "secondary"}
      className={classnames(className, "text-light")}
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {available ? <><PlusCircle className="me-2" /><span>In winkelwagen</span></> : "Niet beschikbaar"}
    </Button>
  )
}

