import * as React from "react"
import { graphql, Link } from "gatsby"
import isEqual from "lodash.isequal"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { Layout } from "../../components/layout"
import { Seo } from "../../components/seo"
import { Breadcrumb, Carousel, Col, Container, Form, Row } from "react-bootstrap"
import { formatPrice } from "../../utils/format-price"
import { StoreContext } from "../../context/store-context"
import { ArrowLeft } from "react-bootstrap-icons"
import AddToCartButton from "../../components/AddToCartButton"
import { localVariantsToItems } from "../../utils/gtag"
import { getProductSubTitle, getProductTitle } from "../../components/ProductCard"
import classnames from "classnames"


export default function Product({ data: { product } }) {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    description,
    descriptionHtml,
    images,
    handle,
    images: [firstImage],
    collections,
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  React.useEffect(() => {
    // Event tracking
    const viewEventData = {
      currency: product.priceRangeV2.minVariantPrice.currencyCode,
      amount: quantity * Number(productVariant.price),
      items: localVariantsToItems([{
        product: product,
        variant: productVariant,
        quantity: quantity,
      }]),
    }
    typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.gtag("event", "view_item", viewEventData)

  }, [productVariant, quantity]);

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )
  const collection = collections[0] || {
    id: '_other',
    handle: '_other',
    title: 'Andere',
  };

  const title = getProductTitle(product);
  const subtitle = getProductSubTitle(product);
  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = images.length > 1

  const hasCollections = collections && collections.length > 0;

  return (
    <Layout>
      <Container className="py-4">
        <Row>
          <Col className="mb-4">
            <Breadcrumb>
              <li className="breadcrumb-item">
                <Link to="/producten/" className="text-primary fw-bold"><ArrowLeft/> Producten</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/producten/categorie/${collection.handle}/`} className="text-primary fw-bold">{collection.title}</Link>
              </li>
              <li className="breadcrumb-item active">
                {title}
              </li>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <Carousel controls={hasMultipleImages} fade={true} indicators={hasMultipleImages}>
              {
                images.map(image => {
                  return (
                    <Carousel.Item key={image.id}>
                      <GatsbyImage objectFit="contain" alt={image.altText || `Afbeelding van ${title}`} image={getImage(image.localFile)} />
                    </Carousel.Item>
                  )
                })
              }
            </Carousel>
          </Col>
          <Col xs={12} md={6} lg={8}>
            <h1 className={classnames({"mb-0": !!subtitle, "mb-4": !subtitle})}>{title}</h1>
            {
              subtitle && <h3 className="text-muted mb-4">{subtitle}</h3>
            }
            <div className="mb-4" dangerouslySetInnerHTML={{'__html': descriptionHtml}}></div>
            {/*{*/}
            {/*  hasCollections && (*/}
            {/*    <div>*/}
            {/*      <span className="fw-bold">Categorieën:</span> {*/}
            {/*        collections.map(collection => {*/}
            {/*          return (*/}
            {/*            <Link to={`/producten/categorie/${collection.handle}`} className="btn btn-sm btn-secondary text-light ms-2">{collection.title}</Link>*/}
            {/*          )*/}
            {/*        })*/}
            {/*      }*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*}*/}
            <h2>{price}</h2>
            {
              variants.length === 1 && (
                <div className="mb-4 mb-lg-5">
                  {variants[0].selectedOptions.map(option => {
                    return (
                      <React.Fragment key={option.name}>
                        <p className="mb-0 text-capitalize fw-bold">{option.name}</p>
                        <p className="mb-0">{option.value}</p>
                      </React.Fragment>
                    )
                  })}
                </div>
              )
            }
            {
              hasVariants && (
                <div className="mb-4 mb-lg-5">
                  {
                    options.map(({ id, name, values }, index) => {
                      return (
                        <Form.Group key={id}>
                          <Form.Label className="text-capitalize  fw-bold">{name}</Form.Label>
                          <Form.Select value={variant.selectedOptions[index].value}
                                       onChange={(event) => handleOptionChange(index, event)}>
                            {values.map((value) => (
                              <option value={value} key={`${name}-${value}`}>
                                {value}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      )
                    })
                  }
                </div>
              )
            }
            <Row>
              <Col xs={4} md={3} lg={2}>
                <Form.Control className="ps-4 pe-2" value={quantity} onChange={(event) => setQuantity(parseInt(event.target.value))} type="number" min={0} max={20} />
              </Col>
              <Col className="d-flex">
                <AddToCartButton className="flex-grow-1 flex-sm-grow-0" product={product} variant={productVariant} variantId={productVariant.storefrontId} quantity={quantity} available={available} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    product: shopifyProduct(id: {eq: $id}) {
      title
      description
      tags
      handle
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        altText
        id
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 480)
          }
        }
      }
      variants {
        shopifyId
        availableForSale
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
      descriptionHtml
      collections {
        id
        handle
        title
      }
      metafields {
        key
        value
      }
    }
  }
`

export const Head = ({ data: { product } }) => {
  const {
    description,
    images: [firstImage],
  } = product

  const title = getProductTitle(product);

  return (
    <Seo
      title={title}
      description={description}
      image={firstImage && getSrc(firstImage.localFile)}
    />
  )
}
